import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router'

// Routes
import Congratulations from './containers/Congratulations'
import FifteenCardPick from './containers/FifteenCardPick'
import FiveCardPick from './containers/FiveCardPick'
import { Description } from './containers/Welcome/index'
import Introduction from './containers/Welcome/Introduction'

import { getClient } from 'actions/client'
import { setExerciseIdAndClientId } from 'actions/auth'
import { getContacts } from 'actions/contacts'
import { getCookie } from 'helpers'
import api from 'helpers/api'
import qs from 'query-string'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from 'reducers'
import MaintenancePage from 'containers/Maintenance'
import { MAINTENANCE_MODE } from 'helpers'

export interface AppProps {
  dispatch: Dispatch<GlobalState>
  exerciseId: string
  clientId: string
  householdId: string
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    if (MAINTENANCE_MODE) return
    const { dispatch, householdId } = this.props
    const query = qs.parse(window.location.search)
    // Get the AdvisorCenter and GuideCenter Tokens
    const acToken = getCookie('acToken')
    const gcToken = getCookie('gcToken')
    const redirectUri = getCookie('redirectUrl') as string
    if (redirectUri) {
      sessionStorage.setItem('redirect_uri', redirectUri)
    }

    if (!acToken && !gcToken) {
      window.location.replace(window._env_.REACT_APP_GUIDECENTER_URL)
    }

    // Get the exercise and client from the URL if its passed in
    let exerciseId = query ? (query.exerciseId as string) : null
    let clientId = query ? (query.clientId as string) : null

    // Store the exercise and client id in case of a refresh or redirect
    // If we don't have the exercise and client because of a redirect or refresh then fallback to session storage
    if (exerciseId) {
      sessionStorage.setItem('exerciseId', exerciseId)
    } else {
      exerciseId = sessionStorage.getItem('exerciseId')
    }

    if (clientId) {
      sessionStorage.setItem('clientId', clientId)
    } else {
      clientId = sessionStorage.getItem('clientId')
    }

    // Check if we have a GC token first. This is to take care of the case where a user has
    // both tokens and somehow a stored clientId in sessionStorage.
    if (gcToken) {
      api.createInstance(gcToken)
    } else {
      // Create the API Instance for Advisor SSO
      if (acToken && clientId) {
        api.createInstance(acToken, clientId)
      }
    }

    // If no householdId present, obtain it through getClient call
    if (this.props && !householdId) {
      dispatch(getClient())
    }

    if (clientId) {
      // @ts-ignore
      pendo.initialize({
        visitor: {
          id: clientId // Required if user is logged in
        }
      })
    }

    // Store the exercise and client id in the redux store so we can use it globally
    dispatch(setExerciseIdAndClientId(exerciseId, clientId))
  }

  async componentDidUpdate(prevProps: AppProps) {
    if (prevProps.householdId !== this.props.householdId) {
      await this.props.dispatch(getContacts())
    }
  }

  render() {
    const { exerciseId, clientId } = this.props
    const acToken = getCookie('acToken')
    const gcToken = getCookie('gcToken')
    // If we don't have an exerciseId we don't know what exercise we're doing
    // If we don't have an acToken and clientId we need the gcToken to figure out which client we're using
    if (
      !MAINTENANCE_MODE &&
      (!exerciseId || (!(clientId && acToken) && !gcToken))
    ) {
      return null
    }
    return (
      <Fragment>
        <Switch>
          <Route exact path={`/maintenance`} component={MaintenancePage} />
          <Route path={`/`} component={Introduction} exact={true} />
          <Route path={`/welcome`} component={Description} exact={true} />
          <Route path={`/pick/five`} component={FiveCardPick} exact={true} />
          <Route
            path={`/pick/fifteen`}
            component={FifteenCardPick}
            exact={true}
          />
          <Route
            path={'/congratulations'}
            component={Congratulations}
            exact={true}
          />
        </Switch>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    exerciseId: store.auth.exerciseId,
    clientId: store.auth.clientId,
    householdId: store.client.householdId
  }
}

export default connect(mapStateToProps)(App)
