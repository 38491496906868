import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { MobileAppHeader } from 'components/AppHeader'
import { CardCategoryType } from 'objects/card'
import {
  ClientCardObj,
  ClientCardPickFiveObj,
  ClientCardInterface
} from 'objects/clientCard'
import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { history } from 'store'
import FiveCardMobileDeck from './FiveCardMobileDeck'
import { GlobalState } from 'reducers'
import {
  addOrEditCardFiveRanking,
  addOrEditCardFiveRankingWithExerciseId,
  getClientCardsWithExerciseId
} from 'actions/clientCard'
import Disclosure from 'components/Disclosure'

interface FiveCardMobileTableProps {
  clientCards: ClientCardInterface
  protectionCards: ClientCardObj[]
  commitmentCards: ClientCardObj[]
  happinessCards: ClientCardObj[]
  selectedCards: ClientCardObj[]
  location?: Location
  dispatch: Dispatch<GlobalState>
  exerciseId: string
}

interface FiveCardMobileTableState {
  protectionCards: ClientCardObj[]
  commitmentCards: ClientCardObj[]
  happinessCards: ClientCardObj[]
  selectedCards: ClientCardObj[]
  showCardPickDrawer: boolean
  expandedDeck: CardCategoryType
  activateFinishButton: boolean
}

class FiveCardMobileTable extends Component<
  FiveCardMobileTableProps,
  FiveCardMobileTableState
> {
  constructor(props: FiveCardMobileTableProps) {
    super(props)
    this.state = {
      protectionCards: this.props.protectionCards,
      commitmentCards: this.props.commitmentCards,
      happinessCards: this.props.happinessCards,
      expandedDeck: null,
      selectedCards: [],
      showCardPickDrawer: false,
      activateFinishButton: false
    }
  }

  public async componentDidMount() {
    const { dispatch, exerciseId } = this.props
    if (exerciseId) {
      dispatch(getClientCardsWithExerciseId(exerciseId))
    }
  }

  toggleCardPickDrawer = () => {
    const { showCardPickDrawer } = this.state
    this.setState({ showCardPickDrawer: !showCardPickDrawer })
  }

  toggleExpandCard = (cardType: CardCategoryType) => {
    const { expandedDeck } = this.state
    this.setState({
      expandedDeck: expandedDeck !== cardType ? cardType : null
    })
  }

  addPick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    card: ClientCardObj
  ) => {
    e.stopPropagation()
    const { selectedCards } = this.state
    if (selectedCards.length <= 4) {
      const destClone = Array.from(selectedCards)
      destClone.push(card)
      destClone.length === 5
        ? this.setState({
            selectedCards: destClone,
            showCardPickDrawer: true,
            activateFinishButton: true
          })
        : this.setState({ selectedCards: destClone })
    }
  }

  removePick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    indexSource: number
  ) => {
    e.stopPropagation()
    const { selectedCards, activateFinishButton } = this.state
    const sourceClone = Array.from(selectedCards)
    sourceClone.splice(indexSource, 1)
    activateFinishButton
      ? this.setState({
          selectedCards: sourceClone,
          activateFinishButton: false
        })
      : this.setState({ selectedCards: sourceClone })
  }

  reorder = (list: ClientCardObj[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    const fiveCardPickTableResult = this.reorder(
      this.state.selectedCards,
      source.index,
      destination.index
    )
    this.setState({ selectedCards: fiveCardPickTableResult })
  }

  cardPickDrawer = () => {
    const { showCardPickDrawer } = this.state
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div
          className={
            showCardPickDrawer
              ? 'five-card-mobile-table__pick-w five-card-mobile-table__pick-w--open'
              : 'five-card-mobile-table__pick-w'
          }>
          <span
            onClick={this.toggleCardPickDrawer}
            className={
              showCardPickDrawer
                ? 'five-card-mobile-table__pick five-card-mobile-table__pick--open'
                : 'five-card-mobile-table__pick'
            }>
            {showCardPickDrawer
              ? 'See Less'
              : `see  ${this.state.selectedCards.length} of 5`}
          </span>
          <div
            className={
              showCardPickDrawer
                ? 'five-card-mobile-table__pick-list five-card-mobile-table__pick-list--show'
                : 'five-card-mobile-table__pick-list'
            }>
            <Droppable droppableId='droppable3'>
              {(droppableProvided) => (
                <Fragment>
                  <div ref={droppableProvided.innerRef}>
                    {this.state.selectedCards.map(
                      (card: ClientCardObj, index: number) => (
                        <Draggable
                          key={card.id}
                          draggableId={card.id}
                          index={index}>
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={{
                                position: 'relative',
                                zIndex: 5 - index,
                                marginBottom: '-30px',
                                ...draggableProvided.draggableProps.style
                              }}>
                              <Card
                                category={card.card.category}
                                title={card.card.title}
                              />
                              <span
                                className='five-card-mobile-table__pick-remove'
                                onClick={(e) => this.removePick(e, index)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                  </div>
                  {droppableProvided.placeholder}
                </Fragment>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    )
  }

  finishExerciseButton = () => {
    const { selectedCards, activateFinishButton } = this.state
    const { dispatch, exerciseId } = this.props
    if (activateFinishButton) {
      let cardObj: any = {}
      const selectedCardArray: ClientCardPickFiveObj[] = []
      const removedCards = this.props.selectedCards.filter(
        (selectedCard: ClientCardObj) => !selectedCards.includes(selectedCard)
      )
      let removedCardIndex = 0
      if (selectedCards[0] && selectedCards[0].id) {
        selectedCards.map((selectedCard, index) => {
          if (this.props.selectedCards.includes(selectedCard)) {
            cardObj = {
              ...selectedCard,
              ranking: index + 1
            }
          } else {
            cardObj = {
              ...selectedCard,
              id:
                removedCards &&
                removedCards[removedCardIndex] &&
                removedCards[removedCardIndex].id,
              ranking: index + 1,
              rankedWithin: 'All'
            }
            removedCardIndex = removedCardIndex + 1
          }
          selectedCardArray.push(cardObj)
        })
        if (exerciseId && exerciseId !== 'null') {
          dispatch(
            addOrEditCardFiveRankingWithExerciseId(
              selectedCards[0].clientId,
              selectedCardArray,
              exerciseId
            )
          )
        } else {
          dispatch(
            addOrEditCardFiveRanking(
              selectedCards[0].clientId,
              exerciseId,
              selectedCardArray
            )
          )
        }
      }
      history.push('/congratulations')
    }
    return
  }

  goBack = () => {
    history.push('/pick/fifteen')
  }

  render() {
    const {
      protectionCards,
      commitmentCards,
      happinessCards,
      selectedCards,
      expandedDeck,
      activateFinishButton
    } = this.state
    return (
      <div className='five-card-mobile-table__w'>
        <div className='five-card-mobile-table__card-w'>
          {(!expandedDeck || expandedDeck === 'Protection') && (
            <FiveCardMobileDeck
              expanded={expandedDeck === 'Protection'}
              cardType='Protection'
              cards={protectionCards}
              pickedCards={selectedCards}
              addPick={this.addPick}
              toggleExpandCard={this.toggleExpandCard}
            />
          )}
          {(!expandedDeck || expandedDeck === 'Commitment') && (
            <FiveCardMobileDeck
              expanded={expandedDeck === 'Commitment'}
              cardType='Commitment'
              cards={commitmentCards}
              pickedCards={selectedCards}
              addPick={this.addPick}
              toggleExpandCard={this.toggleExpandCard}
            />
          )}
          {(!expandedDeck || expandedDeck === 'Happiness') && (
            <FiveCardMobileDeck
              expanded={expandedDeck === 'Happiness'}
              cardType='Happiness'
              cards={happinessCards}
              pickedCards={selectedCards}
              addPick={this.addPick}
              toggleExpandCard={this.toggleExpandCard}
            />
          )}
          {this.cardPickDrawer()}
        </div>
        <Disclosure style={{ paddingBottom: 60 }} />
        <MobileAppHeader
          nextButtonText={'Finish'}
          nextButtonProceed={activateFinishButton}
          backButtonOnClick={this.goBack}
          nextButtonOnClick={
            activateFinishButton
              ? this.finishExerciseButton
              : this.toggleCardPickDrawer
          }
          backButtonRequired={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  const clientCards = store.clientCard
  const selectedCards = Object.keys(clientCards)
    .filter((id) => clientCards[id].rankedWithin === 'All')
    .map((id) => clientCards[id])
    .sort((a, b) => a.ranking - b.ranking)
  return {
    selectedCards,
    clientCards: store.clientCard,
    exerciseId: store.auth.exerciseId
  }
}

export default connect(mapStateToProps)(FiveCardMobileTable)
