import { AppHeader } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import HcLogo from 'assets/images/hcLogo.png'
import React, { Component, CSSProperties, ReactNode } from 'react'
import { GlobalState } from 'reducers'
import { connect, Dispatch } from 'react-redux'
import { getClient } from 'actions/client'

import Radium from 'radium'

export interface HeaderProps {
  dispatch: Dispatch<GlobalState>
  progressBarStyles: CSSProperties
  clientName: string
  middleHeader?: ReactNode
  rightHeader?: ReactNode
}

export interface HeaderState {
  deviceType: 'mobile' | 'tablet' | 'desktop'
}

export interface OverrideStyleInterface {
  wrapper: CSSProperties
  titleWrapper: CSSProperties
  hhName: CSSProperties
  logo: CSSProperties
}

const overrideStyle: OverrideStyleInterface = {
  wrapper: {
    justifyContent: 'center',
    '@media (max-width: 1042px)': {
      padding: '15px'
    },
    '@media (max-width: 750px)': {
      padding: '8px',
      justifyContent: 'center'
    }
  } as CSSProperties,
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  } as CSSProperties,
  hhName: {
    '@media (max-width: 750px)': {
      display: 'none'
    }
  } as CSSProperties,
  logo: {
    maxWidth: '205px',
    '@media (max-width: 750px)': {
      maxWidth: '175px',
      borderLeft: 'none',
      margin: 0
    }
  } as CSSProperties
}

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props)
    this.state = {
      deviceType: null
    }
  }

  componentDidMount() {
    const { dispatch, clientName } = this.props
    if (!clientName) {
      dispatch(getClient())
    }
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 750) {
      this.setState({ deviceType: 'mobile' })
    } else if (window.innerWidth > 900) {
      this.setState({ deviceType: 'desktop' })
    } else {
      this.setState({ deviceType: 'tablet' })
    }
  }

  render() {
    const { clientName, middleHeader, rightHeader } = this.props
    const { deviceType } = this.state
    return (
      <Radium.StyleRoot>
        <AppHeader
          logoSrc={HcLogo}
          logoAlt='HonestConversations'
          logoStyles={overrideStyle.logo}
          hhName={deviceType === 'mobile' ? null : clientName}
          hhNameStyles={overrideStyle.hhName}
          progressBarStyles={{ width: '100%' }}
          wrapperStyles={overrideStyle.wrapper}
          rightHeader={rightHeader ? rightHeader : null}
          middleHeader={middleHeader ? middleHeader : null}
          titleWrapperStyles={overrideStyle.titleWrapper}
        />
      </Radium.StyleRoot>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  const client = store.client
  return {
    clientName: client.name
  }
}

export default connect(mapStateToProps)(Radium(Header))
