import { history } from 'store'
import { LOCATION_CHANGE } from 'react-router-redux'
import { MAINTENANCE_MODE } from 'helpers'

// if MAINTENANCE_MODE is enabled, redirect to maintenance page
export const maintenanceRedirect = (_store: any) => (next: any) => async (
  action: any
) => {
  if (action.type === LOCATION_CHANGE && MAINTENANCE_MODE) {
    if (history.location.pathname !== '/maintenance')
      history.push('/maintenance')
  }
  return next(action)
}
