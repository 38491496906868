import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore, StoreEnhancer } from 'redux'

import { routerMiddleware } from 'react-router-redux'
import reducers, { GlobalState } from './reducers'

import { authentication } from 'middleware/authentication'
import { composeWithDevTools } from 'redux-devtools-extension'
import PromiseMiddleware from 'redux-promise-middleware'
import ReduxThunk from 'redux-thunk'
import navigationToTop from './middleware/navigationToTop'
import { maintenanceRedirect } from 'middleware/maintenanceRedirect'

export const history = createBrowserHistory()
const router = routerMiddleware(history)

const middleware: StoreEnhancer<GlobalState> = composeWithDevTools(
  applyMiddleware(
    PromiseMiddleware(),
    router,
    ReduxThunk,
    maintenanceRedirect,
    navigationToTop,
    authentication
  )
)

export type AppStore = ReturnType<typeof setupStore>
export const store = createStore<GlobalState>(reducers, middleware)

// preloaded state for tests
export const setupStore = (preloadedState?: Partial<GlobalState>) => {
  return createStore<GlobalState>(reducers, preloadedState as any, middleware)
}
