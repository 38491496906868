import { ExerciseInstructionsInterface } from 'objects/exerciseInstructions'
import { routerReducer, RouterState } from 'react-router-redux'
import auth, { AuthenticationInterface } from 'reducers/authentication'
import clientCard, { ClientCardInterface } from 'reducers/clientCard'
import exerciseInstructionsModal from 'reducers/exerciseInstructions'
import client from './client'
import { ClientObj } from 'objects/client'
import { ContactObj } from 'objects/contacts'
import contacts from 'reducers/contacts'
import { AnyAction, combineReducers } from 'redux'

export const appReducer = combineReducers<GlobalState>({
  auth,
  routerReducer,
  clientCard,
  exerciseInstructionsModal,
  client,
  contacts
})

export interface GlobalState {
  auth: AuthenticationInterface
  routerReducer: RouterState
  clientCard: ClientCardInterface
  exerciseInstructionsModal: ExerciseInstructionsInterface
  client: ClientObj
  contacts: ContactObj
}

const rootReducer = (state: GlobalState, action: AnyAction) => {
  return appReducer(state, action)
}

export default rootReducer
